var EmailSignature = function() {
    var self = this;

    this.inputs = {
        nameInput: $('#signature-name'),
        titleInput: $('#signature-title'),
        phoneInput: $('#signature-phone'),
        extensionInput: $('#signature-extension'),
        cellInput: $('#signature-cell'),
        linkedInInput: $('#signature-linkedin'),
        dribbbleInput: $('#signature-dribbble'),
        campaign: $('#signature-campaign')
    };

    this.extension = null;
    this.cell = null;
    this.campaign = null;
    this.campaignHTML = '<a href="http://planetstudio.com/eclipse/?utm_source=emailsig&utm_medium=email&utm_campaign=eclipse"><img src="/images/email-signature/eclipse-campaign.png" alt="Planet Studio" width="200" height="30"></a>';

    this.socialItems = {
        linkedIn: {
            url: null,
            asset: 'linkedin.png',
            alt: 'LinkedIn Profile'
        },
        dribbble: {
            url: 'https://dribbble.com/PlanetStudio',
            asset: 'dribbble.png',
            alt: 'Planet Studio on Dribbble'
        },
        twitter: {
            url: 'http://twitter.com/planetstudio',
            asset: 'twitter.png',
            alt: 'Planet Studio on Twitter'
        },
        medium: {
            url: 'https://dribbble.com/PlanetStudio',
            asset: 'medium.png',
            alt: 'Planet Studio on Medium'
        },
        facebook: {
            url: 'https://www.facebook.com/planetstudio',
            asset: 'facebook.png',
            alt: 'Planet Studio on Facebook'
        },
        instagram: {
            url: 'https://www.instagram.com/_planetstudio/',
            asset: 'instagram.png',
            alt: 'Planet Studio on Instagram'
        }
    };

    this.previewFrame = $('#signature-preview');

    this.init = function() {
        self.bindEvents();
    };

    this.bindEvents = function() {
        for(var input in self.inputs) {
            self.inputs[input].on('keyup', self.render);
            self.inputs[input].on('blur', self.render);
        }

        self.inputs.campaign.on('change', self.render);
    };

    this.render = function() {
        self.extension = self.inputs.extensionInput.val();
        self.cell = self.inputs.cellInput.val();
        self.socialItems.linkedIn.url = self.inputs.linkedInInput.val();
        //self.socialItems.dribbble.url = self.inputs.dribbbleInput.val();

        if(self.inputs.campaign.prop('checked')) {
            self.campaign = "campaign";
        } else {
            self.campaign = "";
        }

        console.log("campaign: " + self.campaign);

        self.previewFrame.contents().find('#name').html(self.inputs.nameInput.val().toUpperCase());
        self.previewFrame.contents().find('#title').html(self.inputs.titleInput.val());
        self.previewFrame.contents().find('#phone').html(self.inputs.phoneInput.val());

        self.renderSocialIcons();

        if(self.extension) {
            self.previewFrame.contents().find('#extension').html('x' + self.extension);
        } else {
            self.previewFrame.contents().find('#extension').html('');
        }

        if(self.cell) {
            self.previewFrame.contents().find('#cell-row').attr('style','');
            self.previewFrame.contents().find('#cell').html(self.cell);
        } else {
            self.previewFrame.contents().find('#cell-row').css({ display: 'none' });
        }

        self.previewFrame.contents().find('#cell').html(self.inputs.cellInput.val());

        if(self.campaign == "campaign") {
            self.previewFrame.contents().find('#campaign-data').html(self.campaignHTML);

        } else {
            self.previewFrame.contents().find('#campaign-data').empty();
        }
    };

    this.renderSocialIcons = function() {
        var html = '';
        var i = 0;
        for(var item in self.socialItems) {
            var cellStyle = 'padding-left:3px; padding-right:3px;';
            if(i === 0) {
                cellStyle = 'padding-right:3px;';
            }
            if(self.socialItems[item].url) {
                // Social Media item url is set
                html += '<td style="' + cellStyle + '">';
                html += '<a href="' + self.socialItems[item].url + '">';
                html += '<img src="/images/email-signature/' + self.socialItems[item].asset + '" alt="' + self.socialItems[item].alt + '" width="18" height="18">';
                html += '</a>';
                html += '</td>';
                i++;
            }
        }
        self.previewFrame.contents().find('#social-media').html(html);
    };

    this.init();
};