var JobForm = function() {

    var self = this;
    this.clientInput = $('#job-client');
    this.primaryContactInput = $('#job-primary-contact');
    this.currentPrimaryContactInput = $('#job-current-primary-contact');
    this.data = {
        client_id: 0,
        current_contact_id: ''
    };

    this.init = function() {
        self.bindEvents();
        self.getCurrentPrimaryContact();
        self.changeClient();
    };

    this.getCurrentPrimaryContact = function() {
        self.data.current_contact_id = parseInt(self.currentPrimaryContactInput.val());
    };

    this.bindEvents = function() {
        $('#job-client').on('change', self.changeClient)
    };

    this.changeClient = function() {
        if(parseInt(self.clientInput.val()) === 0) {
            return false;
        }

        self.primaryContactInput.prop('disabled',true);
        self.data.client_id = self.clientInput.val();
        $.ajax({
            url:'/api/v1/clients/' + self.data.client_id + '/contacts',
            type: 'GET',
            success:function(data) {
                console.log(data);
                self.updateContactList(data);
            },
            error: function() {
                console.log('error getting list of contacts for client');
            }
        });
    };

    this.updateContactList = function(contacts) {
        self.primaryContactInput.html('');
        self.primaryContactInput.append('<option value="0">Choose Primary Contact&hellip;</option>');
        $(contacts).each(function() {
            self.primaryContactInput.append('<option value="' + this.id + '">' + this.first_name + ' ' + this.last_name + '</option>');
        });
        self.primaryContactInput.prop('disabled',false);

        if(self.data.current_contact_id) {
            self.primaryContactInput.val(self.data.current_contact_id);
            self.data.current_contact_id = '';
        }
    };

    this.init();
};