var ClientDetails = function() {

    var self = this;
    this.contactName = null;
    this.formID = null;

    this.init = function() {
        self.bindEvents();
    };

    this.bindEvents = function() {
        $("#delete-modal").on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);

            self.contactName = button.data("contactname");
            self.formID = button.data("formid");

            var modal = $(this);
            modal.find(".modal-title").text("Delete " + self.contactName);
            modal.find(".modal-body").text("Are you sure you want to delete " + self.contactName + "? This cannot be undone.");
        });

        $("#modal-delete-button").on("click", function() {
            $("form#" + self.formID).submit();
        });
    };

    this.init();
};



