var ClientIndex = function() {

    var self = this;
    this.clientName = null;
    this.formID = null;

    this.init = function() {
        self.bindEvents();
    };

    this.bindEvents = function() {
        $("#delete-modal").on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);

            self.clientName = button.data("clientname");
            self.formID = button.data("formid");

            var modal = $(this);
            modal.find(".modal-title").text("Delete " + self.clientName);
            modal.find(".modal-body").text("Are you sure you want to delete " + self.clientName + "? This cannot be undone.");
        });

        $("#modal-delete-button").on("click", function() {
            $("form#" + self.formID).submit();
        });
    };

    this.init();
};



