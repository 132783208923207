var JobIndex = function() {

    var self = this;
    this.jobNumber = null;
    this.formID = null;

    this.init = function() {
        self.bindEvents();
    };

    this.bindEvents = function() {
        $("#delete-modal").on('show.bs.modal', function (event) {
            var button = $(event.relatedTarget);

            self.jobNumber = button.data("jobnumber");
            self.formID = button.data("formid");

            var modal = $(this);
            modal.find(".modal-title").text("Delete Job #" + self.jobNumber);
            modal.find(".modal-body").text("Are you sure you want to delete job #" + self.jobNumber + "? This cannot be undone.");
        });

        $("#modal-delete-button").on("click", function() {
            $("form#" + self.formID).submit();
        });
    };

    this.init();
};



